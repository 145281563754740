<template>
  <Layout>
    <div class="float-right mb-4">
      <b-button class="bseen-btn" @click="showActionModal">Add</b-button>
    </div>
    <div class="clearfix"></div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select
                      v-model="perPage"
                      size="sm"
                      :options="pageOptions"
                    ></b-form-select
                    >&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div
                  id="tickets-table_filter"
                  class="dataTables_filter text-md-right"
                >
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                :items="userData"
                :fields="fields"
                responsive="sm"
                :busy="isBusy"
                :per-page="perPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                </template>
                <template #cell(statusText)="row">
                  <div
                    :style="
                      row.item.status == 0
                        ? 'width:75px;height:25px;background:gray;color:white;display:flex;align-items:center;justify-content:center;border-radius:3px'
                        : 'width:75px;height:25px;background:rgb(0, 193, 0);color:white;display:flex;align-items:center;justify-content:center;border-radius:3px'
                    "
                  >
                    <span v-if="row.item.status == 0">Inactive</span>
                    <span v-else>Active</span>
                  </div>
                </template>
                <template #cell(actions)="row">
                  <button class="action-btn" @click="deleteUser(row.item)">
                    <i class="fas fa-trash-alt"></i>
                  </button>
                  <button class="action-btn" @click="editUser(row.item)">
                    <i class="fas fa-edit"></i>
                  </button>
                </template>
                <template #cell(roles)="row">
                  {{ row.item.roles[0].name }}
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      @change="updatePage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <EditVehicleModal
      :userRoles="userRoles"
      :selectedModel="selectedUser"
      v-on:resetModal="selectedUser = null"
      v-on:reloadData="getAllModels"
      :manufactureNames="manufactureNames"
      :manufactureData="manufactureData"
    />
  </Layout>
</template>

<script>
// import Layout from "../../layouts/main";
import Layout from "../../../layouts/main";
import appConfig from "@/app.config";
import { vehicle } from "@/config/api/vehicles";
import EditVehicleModal from "@/components/vehicle/model/edit-model-vehicle.vue";
import { roles } from "@/config/api/roles";
// import moment from "moment";

export default {
  page: {
    title: "Advanced Table",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, EditVehicleModal },
  data() {
    return {
      manufactureData: {},
      manufactureNames: [],
      userData: [],
      selectedUser: null,
      userRoles: [],
      isBusy: false,
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      fields: [
        { key: "name", label: "Model Name", sortable: false },
        { key: "manufactureName", label: "Manufacture Name", sortable: false },
        { key: "statusText", label: "Status", sortable: false },
        { key: "min_year", sortable: false },
        
        { key: "actions", sortable: false },
      ],
    };
  },
  computed: {
    rows() {
      return this.totalRows;
    },
  },
  mounted() {},
  watch: {
    manufactureNames(val) {
      if (val) {
        this.values = { ...val };
        // this.values.roles = val.roles[0]._id;
        // const index = getObjFromArray(this.schema, "password").index;
        // this.schema[index].validation = null;
      }
    },
    manufactureData(val) {
      if (val) {
        this.values = { ...val };
        // this.values.roles = val.roles[0]._id;
        // const index = getObjFromArray(this.schema, "password").index;
        // this.schema[index].validation = null;
      }
    },
  },
  created() {
    this.getAllModels();
    this.getManufactures();
    this.getAllRoles();
  },
  methods: {
    updatePage(page) {
      this.getAllModels(page);
    },
    async getManufactures() {
      const api = vehicle.manufacture.get;
      this.generateAPI(api)
        .then((res) => {
          res.data.manufactures.forEach((manufacture) => {
            this.manufactureNames.push({label: manufacture.name, value: manufacture._id});
          });
          this.manufactureData = res.data.manufactures;
        })
        .catch((err) => {
          console.log("=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-");
          console.log(err);
          console.log("=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-");
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getAllModels(page = 1) {
      this.isBusy = true;
      const api = vehicle.model.get;
      api.params = {page: page}
      this.generateAPI(api)
        .then((res) => {
          console.log("=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-");
          console.log("All models");
          console.log(res.data);
          console.log("=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-");
          for (var model in res.data.model) {
            res.data.model[model].manufactureName =
              res.data.model[model].manufacture.name;
            res.data.model[model].statusText =
              res.data.model[model].status == 0 ? "Pending" : "Confirmed";
            // res.data.model[model].updatedDate = moment(
            //   res.data.model[model].updatedAt
            // ).format("dddd, MMMM Do YYYY, h:mm:ss a");
            // if (res.data.model[model].deletedBy != null) {
            //   res.data.model[model].deletedByUser =
            //     res.data.model[model].deletedBy.name;
            //   res.data.model[model].deletedDate = moment(
            //     res.data.model[model].deletedAt
            //   ).format("dddd, MMMM Do YYYY, h:mm:ss a");
            // }
          }
          this.userData = res.data.model;
          this.totalRows = res.data.total_no_of_documents;
          this.isBusy = false;
        })
        .catch((err) => {
          console.error(err.response.data);
        });
    },
    showActionModal() {
      this.$bvModal.show("add-edit-model");
    },
    getAllRoles() {
      const data = roles.get;
      this.generateAPI(data)
        .then((res) => {
          this.userRoles = res.data.roles;
        })
        .catch((err) => {
          console.error(err.response.data);
        });
    },
    editUser(data) {
      console.log(data);
      this.selectedUser = data;
      this.showActionModal();
    },
    deleteUser(data) {
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you want to delete ${data.name}.`, {
          title: "Please Confirm",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "YES",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            const api = vehicle.model.delete;
            api.id = data._id;
            this.generateAPI(api)
              .then((res) => {
                console.log(res.data);
                this.$bvToast.toast("Admin deleted successfully!", {
                  title: "Delete",
                  variant: "success",
                  solid: true,
                });
                this.getAllModels();
              })
              .catch((err) => {
                this.$bvToast.toast("Admin is not deleted!", {
                  title: "Delete",
                  variant: "danger",
                  solid: true,
                });
                console.error(err.response.data);
              });
          }
        })
        .catch((err) => {
          console.error(err);
          // An error occurred
        });
    },
  },
};
</script>
