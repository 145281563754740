<template>
  <b-modal
    id="add-edit-model"
    @hidden="$emit('resetModal')"
    :title="selectedModel ? 'Update Model' : 'Add Model'"
    hide-footer
  >
    <div v-if="selectedModel">
      <FormulateForm
        name="updateModelForm"
        v-model="values"
        :schema="updateSchema"
        @submit="submitHandler"
      >
        <FormulateInput type="submit" class="float-right">
          <span v-if="performAction">
            <b-spinner class="vueformulate-loader"></b-spinner>
            <span v-if="selectedModel"> Updating... </span>
            <span v-else> Adding... </span>
          </span>
          <span v-else>
            <span v-if="selectedModel"> Update </span>
            <span v-else> Add </span>
            Model</span
          >
        </FormulateInput>
      </FormulateForm>
    </div>
    <div v-else>
      <FormulateForm
        name="createModel"
        v-model="values"
        :schema="addSchema"
        @submit="submitHandler"
      >
        <FormulateInput type="submit" class="float-right">
          <span v-if="performAction">
            <b-spinner class="vueformulate-loader"></b-spinner>
            <span> Adding... </span>
          </span>
          <span v-else>Add Model</span>
        </FormulateInput>
      </FormulateForm>
    </div>
  </b-modal>
</template>

<script>
// import { getObjFromArray } from "@/utils/getObjFromArray";
import { vehicle } from "@/config/api/vehicles";
export default {
  props: ["selectedModel", "manufactureNames", "manufactureData"],
  data() {
    return {
      values: {},
      performAction: false,
      addSchema: [
        {
          index: 0,
          type: "text",
          name: "name",
          label: "Modal Name",
          validation: "required",
        },
        {
          index: 1,
          type: "select",
          name: "manufacture",
          label: "Manufacture",
          options: this.manufactureNames,
          validation: "required",
        },
        {
          index: 2,
          type: "select",
          name: "min_year",
          label: "Min year",
          options: Array(2022 - 1950 + 1)
            .fill()
            .map((_, idx) => 1950 + idx),
          validation: "required",
        },
        {
          index: 3,
          type: "select",
          name: "statusText",
          label: "Status",
          options: ["Pending", "Confirmed"],
          validation: "required",
        },
        //Ask Ali how to make this an image selector
        // {
        //   index: 2,
        //   type: "password",
        //   name: "password",
        //   label: "Password",
        //   validation: "required",
        // },
      ],
      updateSchema: [
        {
          index: 0,
          type: "text",
          name: "name",
          label: "Modal Name",
          validation: "required",
        },
        {
          index: 1,
          type: "select",
          name: "manufacture",
          label: "Manufacture",
          options: this.manufactureNames,
          validation: "required",
        },
        {
          index: 2,
          type: "select",
          name: "min_year",
          label: "Min year",
          options: Array(2022 - 1950 + 1)
            .fill()
            .map((_, idx) => 1950 + idx),
          validation: "required",
        },
        {
          index: 3,
          type: "select",
          name: "statusText",
          label: "Status",
          options: ["Pending", "Confirmed"],
          validation: "required",
        },
      ],
    };
  },
  created() {
    console.log("this");
    console.log(this);
  },
  methods: {
    async submitHandler(data) {
      if (data.statusText.toLowerCase() == "pending") {
        data.status = 0;
      } else {
        data.status = 1;
      }
      if (this.selectedModel) {
        this.updateModel(data, this.selectedModel._id);
      } else {
        this.addModel(data);
      }
    },
    addModel(data) {
      console.log(data)
      this.performAction = true;
      const api = vehicle.model.create;
      api.data = data;
      this.generateAPI(api)
        .then(() => {
          // this.$formulate.reset("updateModelForm");
          this.$bvToast.toast("Admin added successfully!", {
            title: "Create",
            variant: "success",
            solid: true,
          });

          this.$emit("reloadData");
        })
        .catch((err) => {
          this.$bvToast.toast("Admin is not added!", {
            title: "Create",
            variant: "danger",
            solid: true,
          });
          console.log(err);
          // console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
    updateModel(data, id) {
      this.performAction = true;
      const api = vehicle.model.update;
      api.data = data;
      api.id = id;
      this.generateAPI(api)
        .then((res) => {
          console.log(res.data);
          this.$bvToast.toast("Admin updated successfully!", {
            title: "Update",
            variant: "success",
            solid: true,
          });
          this.$emit("reloadData");
        })
        .catch((err) => {
          this.$bvToast.toast("Admin is not updated!", {
            title: "Update",
            variant: "danger",
            solid: true,
          });
          console.error(err.response.data);
        })
        .finally(() => {
          this.performAction = false;
        });
    },
  },
  watch: {
    selectedModel(val) {
      if (val) {
        this.values = { ...val };
      } else {
        this.values = null;
      }
    },
  },
};
</script>

<style></style>
